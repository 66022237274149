import React from 'react';
import { Link } from 'react-router-dom';

interface SocialIconProps {
  src: string;
  alt: string;
  link: string;
}

const SocialIcon: React.FC<SocialIconProps> = ({ src, alt, link }) => {
  return (
    <Link to={link}>
      <div className="flex gap-2 justify-center items-center px-3 w-10 h-10 bg-slate-200 min-h-[40px] rounded-[96px] cursor-pointer">
        <img
          loading="lazy"
          src={src}
          className="object-contain self-stretch my-auto aspect-[0.9] w-[18px]"
          alt={alt}
        />
      </div>
    </Link>
  );
};

export default SocialIcon;
