import { xLogo, discordLogo, telegramLogo } from '../../assets';

export const footerColumns = [
  {
    title: 'Products',
    items: [
      'Sell Luxury Real Estate',
      'Sell Vacation Homes',
      'Sell Single-Family Homes',
      'Sell Retail Real Estate',
    ],
    links: ['/project', '/project', '/project', '/project'],
  },
  {
    title: 'Company',
    items: ['FAQ', 'Audit', 'Scout Program', 'Contact', 'Careers'],
    links: [
      'https://docs.futurestarter.xyz/',
      '/',
      '/',
      'mailto:hello@futurestarter.xyz',
      'https://angel.co/company/fsmultichain/',
    ],
  },
  {
    title: 'Legal',
    items: ['Terms & Conditions'],
    links: ['https://app.fantomstarter.io/terms'],
  },
];

export const socialIcons = [
  {
    src: xLogo,
    alt: 'x',
    link: 'https://blog.futurestarter.xyz/',
  },
  {
    src: discordLogo,
    alt: 'discord',
    link: 'https://blog.futurestarter.xyz/',
  },
  {
    src: telegramLogo,
    alt: 'telegram',
    link: 'https://blog.futurestarter.xyz/',
  },
];
