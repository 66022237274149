import React from 'react';
import SocialIcon from './SocialIcon';
import FooterColumn from './FooterColumn';
import { footerColumns, socialIcons } from './footerData';
import { Link } from 'react-router-dom';
import { g8wyFooterLogo, slowmistLogo } from '../../assets';

const Footer: React.FC = () => {
  return (
    <footer className="flex flex-wrap justify-between items-start px-32 py-20 w-full bg-white mt-[100px] max-md:px-5 max-md:mt-10 max-md:max-w-full gap-10 sm:gap-0">
      <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
        <div className="flex flex-col w-full min-h-[160px] max-md:max-w-full">
          <div className="flex overflow-hidden flex-1 gap-3 items-center self-start h-full">
            <img
              loading="lazy"
              src={g8wyFooterLogo}
              alt="footer-logo"
              className="h-[92px] w-[254px]"
            />
          </div>
          <p className="mt-2 w-[200px] text-sm leading-5 text-slate-700 max-md:max-w-full">
            © 2021-2024 G8WY. By using this service you agree to
            the{' '}
            <Link to="https://app.fantomstarter.io/terms" className="underline">
              Terms & Conditions
            </Link>
          </p>
        </div>
        <div className="flex overflow-hidden flex-col items-start pr-3 pl-2.5 mt-14 w-full max-md:mt-10 max-md:max-w-full">
          <img
            loading="lazy"
            src={slowmistLogo}
            className="object-contain max-w-full aspect-[2.94] w-[156px]"
            alt="audited-by"
          />
        </div>
      </div>
      <nav className="flex flex-wrap gap-10 items-start min-w-[240px] max-md:max-w-full">
        {footerColumns.map((column, index) => (
          <FooterColumn
            key={index}
            title={column.title}
            items={column.items}
            links={column.links}
          />
        ))}
        <div className="flex flex-col w-[145px]">
          <h2 className="text-xl font-semibold leading-10 text-slate-900">
            Follow us
          </h2>
          <div className="flex gap-2 items-start mt-2 w-full">
            {socialIcons.map((icon, index) => (
              <SocialIcon
                key={index}
                src={icon.src}
                alt={icon.alt}
                link={icon.link}
              />
            ))}
          </div>
        </div>
      </nav>
    </footer>
  );
};

export default Footer;
