import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useAccount } from 'wagmi';

import { AuthLogoSection } from '../../components/Hero';
import { AuthForm } from '../../components/Form';
import { AuthButtons } from '../../components/Buttons';

const Login = () => {
  const { open } = useWeb3Modal();
  const { isConnected } = useAccount();
  const navigate = useNavigate();

  const handleFormSubmit = (email: string) => {
    console.log('Form submitted with email:', email);
    open();
  };

  const handleContinueWithGoogle = () => {
    console.log('Continue with Google');
    open();
  };

  const handleContinueWithApple = () => {
    console.log('Continue with Apple');
    open();
  };

  useEffect(() => {
    if (isConnected) navigate('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  return (
    <main className="bg-white w-screen h-screen flex flex-row items-center">
      <AuthLogoSection />
      <section className="w-2/5 p-6 flex flex-col items-center">
        <div className="flex flex-col items-center">
          <h1 className="text-3xl font-semibold">Log In</h1>
          <p className="text-xl font-normal mt-2">
            Enter your email below to log in
          </p>
        </div>
        <AuthForm onSubmit={handleFormSubmit} hideTnCBox />
        <section className="flex gap-2 justify-center items-center w-full max-w-[360px] mx-auto mt-8 leading-none text-center text-[#334155]">
          <div className="flex-1 shrink self-stretch my-auto h-px border border-solid basis-0 border-[#E2E8F0] w-[111px]" />
          <div className="self-stretch my-auto">OR CONTINUE WITH</div>
          <div className="flex-1 shrink self-stretch my-auto h-px border border-solid basis-0 border-[#E2E8F0] w-[111px]" />
        </section>
        <AuthButtons
          onContinueWithGoogle={handleContinueWithGoogle}
          onContinueWithApple={handleContinueWithApple}
        />
        <section className="flex flex-row items-center gap-2 justify-center mt-5">
          <p className="text-[#334155]">Don’t have an account?</p>
          <button
            className="btn-text px-0 font-medium"
            onClick={() => navigate('/auth/signup')}
          >
            Sign Up
          </button>
        </section>
      </section>
    </main>
  );
};

export default Login;
