import React, { useState, FormEvent } from 'react';

interface AuthFormProps {
  onSubmit: (email: string) => void;
  hideTnCBox?: boolean;
}

const AuthForm: React.FC<AuthFormProps> = ({
  onSubmit,
  hideTnCBox = false,
}) => {
  const [email, setEmail] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!hideTnCBox && !isChecked)
      alert('Please agree to the Terms & Conditions.');
    onSubmit(email);
  };

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-[360px] mx-auto mt-8">
      <div className="mb-4">
        <input
          type="email"
          placeholder="name@example.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full p-3 border rounded-md bg-white outline-none"
          required
        />
      </div>
      {!hideTnCBox && (
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
            className="mr-2 cursor-pointer accent-white bg-white"
          />

          <label className="font-medium">
            I agree to G8WY’s Terms & Conditions
          </label>
        </div>
      )}
      <button
        type="submit"
        className={`btn-primary w-full ${!hideTnCBox && !isChecked ? 'opacity-50 cursor-not-allowed' : ''}`}
        disabled={!hideTnCBox && !isChecked}
      >
        Continue
      </button>
    </form>
  );
};

export default AuthForm;
