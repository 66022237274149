import { useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import { Footer } from '../../components/Footer';
import { ProjectCard } from '../../components/ProjectCard';
import { NavigationButton } from '../../components/NavigationButton';
import { Link } from 'react-router-dom';

import { plus, projectImg1, projectImg2, projectImg3 } from '../../assets';

interface ProjectData {
  name: string;
  amount: string;
  imageSrc: string;
}

const projectData: ProjectData[] = [
  {
    name: 'Alux 33',
    amount: '$3,884,000 MXN',
    imageSrc: projectImg1,
  },
  {
    name: 'Xunik',
    amount: '$1,555,800.97 MXN',
    imageSrc: projectImg2,
  },
  {
    name: 'Macondo Fase III',
    amount: '$4,215,750 MXN',
    imageSrc: projectImg3,
  },
];

export default function Home() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextCard = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + projectData.length) % projectData.length
    );
  };

  const prevCard = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % projectData.length);
  };

  return (
    <>
      <div className="overflow-y-hidden sm:overflow-visible bg-white">
        <div className="sm:bg-white">
          <Navbar />
          <div className="pt-32 sm:px-32 px-5 flex flex-col sm:flex-row gap-5 sm:gap-20 items-start sm:items-center">
            <section className="w-full sm:w-[460px] mb-8 sm:mb-0 flex flex-col gap-4">
              <h1 className="text-[#0F172A] font-bold text-4xl sm:text-7xl">
              Real Estate Revolution: Secure, Transparent, Global
              </h1>
              <Link to="/project">
                <button className="w-max flex h-14 px-4 py-2 font-bold justify-center items-center text-white gap-2 rounded-md bg-gradient-to-r from-[#0CB4FC] to-[#D24CFE]">
                  <span>
                    <img src={plus} alt="plus" className="w-4 h-4" />
                  </span>
                  Start selling with G8WY
                </button>
              </Link>
            </section>
            <div className="flex items-center justify-start overflow-hidden">
              <div className="flex items-center gap-4">
                <NavigationButton direction="left" onClick={prevCard} />
                <div className="flex gap-4">
                  {[...Array(projectData.length)].map((_, index) => {
                    const projectIndex =
                      (currentIndex + index) % projectData.length;
                    return (
                      <ProjectCard
                        key={projectIndex}
                        {...projectData[projectIndex]}
                      />
                    );
                  })}
                </div>
                <NavigationButton direction="right" onClick={nextCard} />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
