import React from 'react';
import { Link } from 'react-router-dom';

interface FooterColumnProps {
  title: string;
  items: string[];
  links: string[];
}

const FooterColumn: React.FC<FooterColumnProps> = ({ title, items, links }) => {
  return (
    <div className="flex flex-col w-[145px]">
      <h2 className="text-xl font-semibold leading-10 text-slate-900">
        {title}
      </h2>
      <ul className="flex flex-col mt-2 w-full text-sm font-medium leading-6 text-slate-900">
        {items.map((item, index) => (
          <li
            key={index}
            className="gap-2.5 self-stretch py-2 rounded-md bg-white bg-opacity-0"
          >
            <Link to={links[index]} className="text-slate-900">
              {item}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FooterColumn;
